import React, { useState, useEffect } from 'react';

import { formValueSelector, resetSection } from 'redux-form';
import { Collapse } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Closed from './components/CollapseContainer/Closed';
import { defaultListOfDays } from './helper';
import { AddCirclePlus } from '../../UIKit';
import { additionalFields, devicesInitialValues } from '../../../containers/DevicesTable/utils';
import Opened from './components/CollapseContainer/Opened';
import i18n from '../../../i18n';

import './index.scss';

const RenderSwitchingTimes = (props) => {
  const dispatch = useDispatch();
  const { fields, expectedFields, deviceType, meta, deviceName, userApp } = props;
  const [openSwitches, setOpenSwitches] = useState([]);
  const selector = formValueSelector(meta.form);

  const switchingTimesData = useSelector((state) => selector(state, 'data.switchingTimes'));
  const waterHeaterType = useSelector((state) => selector(state, 'data.waterHeaterType')) || devicesInitialValues[deviceName]?.data?.waterHeaterType;

  const addTimer = () => {
    fields.push({ from: '00:00', to: '00:00', activeDays: defaultListOfDays });
    setOpenSwitches((oldState) => [...oldState, fields.length]);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => dispatch(resetSection(meta.form, 'data.switchingTimes')), []);

  return (
    <>
      {fields.map((switchingTime, index) => {
        const data = switchingTimesData[index];
        const filteredFieldsComponents = expectedFields.filter((item) => {
          const currentFieldContent = additionalFields[item];
          if (currentFieldContent?.switchAction?.checkIfEnabled) {
            return currentFieldContent.switchAction.checkIfEnabled(deviceType, data?.switchMode);
          }
          return true;
        });

        const switchModeName = filteredFieldsComponents.some((item) => item === 'switchMode') ? 'switchMode' : 'switchAction';

        return (
          // eslint-disable-next-line react/no-array-index-key
          <div className="switching-times__collapse" key={index}>
            <div className="collapse-container">
              <Collapse
                in={openSwitches.includes(index)}
                timeout={200}
                easing={{ enter: 'cubic-bezier(0.4, 0.0, 0.2, 1);', exit: 'linear' }}
              >
                <Opened
                  {...props}
                  data={data}
                  waterHeaterType={waterHeaterType}
                  switchTime={switchingTime}
                  filteredFieldsComponents={filteredFieldsComponents}
                  handleToggleCollapse={() => setOpenSwitches((oldState) => oldState.filter((item) => item !== index))}
                  switchIndex={index}
                  license={props.license}
                  userApp={userApp}
                />
              </Collapse>
              <Collapse
                in={!openSwitches.includes(index)}
                timeout={200}
                easing={{ enter: 'cubic-bezier(0.4, 0.0, 0.2, 1);', exit: 'linear' }}
              >
                <Closed
                  data={data}
                  deviceType={deviceType}
                  switchModeName={switchModeName}
                  switchTime={switchingTime}
                  handleToggleCollapse={() => setOpenSwitches((oldState) => [...oldState, index])}
                  userApp={userApp}
                  allowedBatteryModeOptions={props.allowedBatteryModeOptions}
                  tariffType={props.tariffType}
                />
              </Collapse>
            </div>

            <div onClick={() => fields.remove(index)} className="switching-times__collapse-trash">
              <i className="las la-trash-alt" />
            </div>
          </div>
        );
      })}
      <AddCirclePlus
        handleClick={addTimer}
        title={i18n.t('addSwitchingTimesTitle')}
      />
    </>
  );
};

export const SwitchingTimes = (props) => (
  <div className="switching-times__container">
    <div className="switching-times__collapse-container">
      <RenderSwitchingTimes {...props} />
    </div>
  </div>
);

RenderSwitchingTimes.propTypes = {
  fields: PropTypes.instanceOf(Object).isRequired,
  expectedFields: PropTypes.instanceOf(Array).isRequired,
  deviceType: PropTypes.string.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  deviceName: PropTypes.string.isRequired,
  userApp: PropTypes.string.isRequired,
  tariffType: PropTypes.string,
  license: PropTypes.instanceOf(Object).isRequired,
  allowedBatteryModeOptions: PropTypes.instanceOf(Object)
};

SwitchingTimes.propTypes = {
  fields: PropTypes.instanceOf(Object).isRequired,
  deviceType: PropTypes.string.isRequired
};
