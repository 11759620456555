import moment from 'moment-timezone';
import { cloneDeep } from 'lodash';

import { queryURLFormat } from '../../../utils';
import { dayInMS, fiveMinutesInMS, hourInMS, weekInMS } from '../constants';

const fillNoData = ({ singleInterval, data }) => {
  if (data.length <= 1) {
    return data;
  }

  let i = 1;

  while (i < data.length) {
    const [currentTimestamp] = data[i];
    const [prevTimestamp] = data[i - 1];
    const currentInterval = currentTimestamp - prevTimestamp;
    if (currentInterval >= singleInterval * 2) {
      const numberOfPointsToInsert = Math.round(currentInterval / singleInterval) - 1;
      const desiredInterval = Math.round(currentInterval / (numberOfPointsToInsert + 1));

      const pointsToInsert = [];

      let lastTimeStamp = prevTimestamp;

      for (let j = 0; j < numberOfPointsToInsert; j += 1) {
        lastTimeStamp += desiredInterval;
        pointsToInsert.push([lastTimeStamp, null]);
      }

      data.splice(i, 0, ...pointsToInsert);

      i += numberOfPointsToInsert;
    } else {
      i += 1;
    }
  }

  return data;
};

const addPointsToStart = ({ data, singleInterval, from, to }) => {
  if (!data.length) {
    let timestamp = from;

    while (timestamp <= to) {
      data.push([timestamp, null]);

      timestamp += singleInterval;
    }

    return data;
  }

  const [firstTimestamp] = data[0];

  const range = Math.round(firstTimestamp - from);

  if (range < singleInterval) {
    return data;
  }

  const numberOfPointsToInsert = Math.round(range / singleInterval) - 1;
  const desiredInterval = Math.round(range / (numberOfPointsToInsert + 1));

  let count = 0;

  let timestamp = from;

  const pointsToInsert = [];

  while (count < numberOfPointsToInsert) {
    pointsToInsert.push([timestamp, null]);

    timestamp += desiredInterval;
    count += 1;
  }

  data.splice(0, 0, ...pointsToInsert);

  return data;
};

const addPointsToEnds = ({ data, singleInterval, from, to }) => {
  if (!data.length) {
    let timestamp = from;

    while (timestamp <= to) {
      data.push([timestamp, null]);

      timestamp += singleInterval;
    }

    return data;
  }

  const [lastTimeStamp] = data[data.length - 1];

  const range = Math.round(to - lastTimeStamp);

  if (range < singleInterval) {
    return data;
  }

  const numberOfPointsToInsert = Math.round(range / singleInterval) - 1;
  const desiredInterval = Math.round(range / (numberOfPointsToInsert + 1));
  let count = 0;

  let timestamp = lastTimeStamp;

  while (count < numberOfPointsToInsert) {
    data.push([timestamp, null]);

    timestamp += desiredInterval;
    count += 1;
  }

  return data;
};

/**
 * Returns range parameters by scaleType
 * @param scaleType
 * @memberof module:LoadManagementDashboard
 * @returns {{limitsReceived: *, expectedScaleMS: *, from: *, to: *}}
 */
export function getRange(scaleType, chart, subType) {
  let from;
  let to;
  let expectedScaleMS;
  let limitsReceived;
  let newSubType = subType;

  const now = moment().valueOf();
  const prevTo = chart.to > moment(now).valueOf() ? moment(now).valueOf() : moment(chart.to).valueOf();
  const prevRange = {
    from: moment(chart.from).valueOf(),
    to: prevTo,
    middle: prevTo - ((prevTo - moment(chart.from).valueOf()) / 2),
    scaleType: chart.scaleType,
    realTimeData: moment(now).subtract(prevTo) < fiveMinutesInMS * 2
  };

  switch (scaleType) {
    case 'w': {
      if (prevRange.scaleType === 't' || prevRange.realTimeData) {
        from = moment(now).subtract(1, 'week');
        to = moment(now);
      } else {
        from = moment(prevRange.middle || now).subtract(1, 'week');
        to = moment(prevRange.middle || now);
      }
      expectedScaleMS = weekInMS;
      break;
    }
    case 'd': {
      if (prevRange.scaleType === 't' || prevRange.realTimeData) {
        from = moment(now).subtract(1, 'day');
        to = moment(now);
      } else if (moment(prevRange.middle || now).endOf('day') > now) {
        from = moment(prevRange.middle || now).subtract(1, 'day');
        to = moment(prevRange.middle || now);
      } else {
        from = moment(prevRange.middle || now).startOf('day');
        to = moment(prevRange.middle || now).endOf('day');
      }
      expectedScaleMS = dayInMS;
      break;
    }
    case 'h': {
      if (prevRange.scaleType === 't' || prevRange.realTimeData) {
        from = moment(now).subtract(1, 'hour');
        to = moment(now);
      } else {
        from = moment(prevRange.middle || now).subtract(1, 'hour');
        to = moment(prevRange.middle || now);
      }
      expectedScaleMS = hourInMS;
      break;
    }
    case 't': {
      from = moment(now).startOf('day');
      to = moment(now);
      expectedScaleMS = to.diff(from);
      break;
    }
    default: {
      from = chart.from ? moment(chart.from) : moment(now).startOf('day');
      to = chart.to ? moment(chart.to) : moment(now);
      const oldScaleMS = chart.scaleMS ? chart.scaleMS : to.diff(from);

      if (scaleType === 'back') {
        from.subtract(Math.round(oldScaleMS), 'ms');
        to = moment(from).add(oldScaleMS, 'ms');
        expectedScaleMS = oldScaleMS;
      }

      if (scaleType === 'forward') {
        to.add(Math.round(oldScaleMS), 'ms');

        from = moment(to).subtract(oldScaleMS, 'ms');
        expectedScaleMS = oldScaleMS;
      }
      if (typeof scaleType === 'number') {
        let newScale = oldScaleMS * scaleType;
        if (newScale < hourInMS) {
          newScale = hourInMS;
          limitsReceived = { minZoom: true };
          newSubType = 'h';
        } else if (newScale > weekInMS) {
          newScale = weekInMS;
          limitsReceived = { maxZoom: true };
        } else if (hourInMS < newScale < dayInMS) {
          newSubType = 'd';
        }

        const offset = (oldScaleMS - newScale) / 2;
        from.add(offset, 'ms');
        to.subtract(offset, 'ms');
        expectedScaleMS = newScale;
      }
    }
  }

  return {
    from,
    to,
    expectedScaleMS,
    limitsReceived,
    newSubType
  };
}

export const fillDataGaps = (phase, from, to) => {
  const phaseData = cloneDeep(phase);

  addPointsToStart({ data: phaseData, singleInterval: fiveMinutesInMS, from, to });
  addPointsToEnds({ data: phaseData, singleInterval: fiveMinutesInMS, from, to });
  fillNoData({ data: phaseData, singleInterval: fiveMinutesInMS });

  return phaseData;
};

export const chartChangeRange = (from, to, replaceFromProps, locationPathname, subType) => {
  const min = Math.round(from);
  const max = Math.round(to);

  const newSearch = {
    from: min,
    to: max,
    type: 'range',
    subType: subType === 't' ? 'd' : subType
  };
  replaceFromProps(locationPathname + queryURLFormat(newSearch));
};
