import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { flowRight, omit } from 'lodash';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import DataList from '../DataList';
import TabContentContainer from './components/TabContentContainer';
import {
  AddDeviceWindowHoc,
  DeleteDeviceWindow,
  EditDeviceWindowHoc,
  InviteSubUser,
  RemoveSubUser,
  SearchChargersWindow,
  SkiSearchWindow,
  SearchIPWindow,
  BatteryModeModalWindow
} from './modals';
import {
  CarsTab,
  DevicesTab,
  InputDevicesTab,
  InvertersTab,
  LoadGuardTab,
  SmartMeterTab
} from './Tabs';

import {
  openModalWindow,
  modalWindowSend,
  EDIT_ROW_MODAL_ID,
  ADD_DEVICE_MODAL_ID,
  REMOVE_DATA_MODAL_ID,
  INVITE_SUB_USER_MODAL_ID,
  REMOVE_SUB_USER_MODAL_ID,
  ADD_CAR_CHARGERS_WINDOW_MODAL_ID,
  UPGRADE_TO_UNLOCK_POPUP
} from '../ModalWindow/slice';
import { setPagesSetting } from '../PagesSettings/slice';
import { dataListReloadData, getSubUserReloadData } from '../DataList/slice';
import { sensorsOauth2AllowedToDisabledEditModal } from '../SensorsOauth2/slice';
import {
  ChangeColumnsModalWindow,
  ColorPickerModalWindow,
  CommonInfoModalWindow,
  EXPERT_MODAL_ID,
  ADDITIONAL_COST_MODAL_ID
} from '../../modals';
import {
  checkMyRoleTypeSelector,
  editModalDataSelector,
  getSmSubUsersSelectorId,
  getSubSensorsFromSensors,
  getTariffSettingsSelector,
  getUserLicenseSelector
} from './selectors';
import { getPagesSettingsSelector } from '../../redux-store/selectors/pagesSettings';
import { getSensorsOauth2IsDisabledOauth2EditModalSelector } from '../../redux-store/selectors/sensorsOauth2';
import { getDataListSensorsSelector } from '../../redux-store/selectors/dataList';
import {
  getModalsAddDeviceDataSelector,
  getModalsEditRowDataSelector,
  getModalsRemoveDataDataSelector
} from '../../redux-store/selectors/modals';

import { transformFieldsForAPI, omitFakeField, getAddTitle } from './utils';
import { SENSORS_LIST_ID } from '../UserDetails/constants';
import { dataListID } from '../SubUsers/constants';
import {
  LOADGUARDS_URL,
  SENSORS_SUB_METER_URL,
  SENSORS_URL,
  SENSOR_TAGS_URL,
  USERS_SUB_USER_URL
} from '../../api/apiUrls';
import AddCarChargersWindow from './modals/AddCarChargersWindow';
import RemoveCarCharger from './modals/RemoveCarCharger';

import i18n from '../../i18n';
import './index.scss';
import AddCar from './Tabs/CarsTab/AddCar';
import EditCar from './Tabs/CarsTab/EditCar';
import { Tooltip } from '../../components';
import { isInvitedUserHasReadOnlyPermissionSelector, isPvEmployeeInstallHasReadOnlyAccessSelector } from '../../redux-store/selectors/userDevices';
import { getUserInfo } from '../UserDetails/slice';
import { settingPrGetUser } from '../SettingProfile/slice';
import { signInUpdateUser } from '../SignIn/slice';
import { useGetFeatureFlagsQuery } from './services';

const tagsListID = 'deviceTags';
const ZEV_ZOHO_LINK = 'https://solarmanager.zohodesk.com/portal/de/kb/articles/laststeuerung-rse-signal';
const transformResponse = (data) => ({
  total: data.length,
  data
});
/**
 * @module UserDevices
 */
/**
 * Table with user devices in page UserDetails
 * @memberof module:UserDevices
 */
const DevicesTable = (props) => {
  const owner = useSelector(checkMyRoleTypeSelector);
  const { myRoleType, userId = owner, license = {}, userApp = '' } = props;
  const dispatch = useDispatch();

  const getModalsAddDeviceData = useSelector(getModalsAddDeviceDataSelector);
  const getModalsRemoveDataData = useSelector(getModalsRemoveDataDataSelector);
  const getModalsEditRowData = useSelector(getModalsEditRowDataSelector);
  const isReadOnlyPermission = useSelector(isInvitedUserHasReadOnlyPermissionSelector);
  const isPvEmployeeInstallHasReadOnlyAccess = useSelector(isPvEmployeeInstallHasReadOnlyAccessSelector);
  const editModalData = useSelector(editModalDataSelector);
  const sensors = useSelector(getDataListSensorsSelector);
  const userDevicesUser_Id = useSelector(getSmSubUsersSelectorId);
  const subSensors = useSelector(getSubSensorsFromSensors);
  const { userDetails: userDetailsSettings = {} } = useSelector(getPagesSettingsSelector);
  const isDisabledOauth2EditModal = useSelector(getSensorsOauth2IsDisabledOauth2EditModalSelector);
  const userLicense = useSelector(getUserLicenseSelector);
  const tariffSettings = useSelector(getTariffSettingsSelector);

  const deviceTypes = {
    0: 'devices',
    1: 'inverters',
    2: 'smart-meter',
    3: 'cars',
    4: 'input-devices',
    5: 'loadguards'
  };

  const type = deviceTypes[userDetailsSettings.userDevicesActiveTab] || 'devices';

  const defaultParams = { _limit: 1000, _sort: 'priority:asc' };

  const { total } = sensors;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const randomColor = useMemo(
    () => `#${Math.floor(Math.random() * 16777215).toString(16)}`,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [type, total, subSensors?.length]
  );
  const { data: featureFlags } = useGetFeatureFlagsQuery();
  const newBatteryMode = featureFlags?.find(({ name }) => name === 'newBatteryMode');
  const updateSettings = () => (myRoleType === 'end_user'
    ? settingPrGetUser({ userID: undefined, myself: true, roleLink: 'solar-managers-users', successfulCB: (result) => dispatch(signInUpdateUser({ user: result })) })
    : getUserInfo({ userId }));

  const subMetersUrl = `${SENSORS_SUB_METER_URL}/${userId}`;
  const devicesUrl = `${SENSORS_URL}/${type}/${userId}`;
  const smartMeterUrl = `${SENSORS_URL}/smart-meter/${userId}`;
  const loadGuardUrl = `${LOADGUARDS_URL}/${userId}`;
  const tagsListURL = `${SENSOR_TAGS_URL}/${userId}`;
  const isSubMeter = [
    getModalsAddDeviceData?.newType,
    getModalsRemoveDataData?.newType,
    getModalsEditRowData?.newType
  ].includes('sub-meter');

  const getNewDevices = dataListReloadData.bind(null, {
    listID: isSubMeter ? 'sub-meter' : SENSORS_LIST_ID
  });

  const changeType = (index) => {
    if (deviceTypes?.[index]) {
      dispatch(setPagesSetting({ page: 'userDetails', data: { userDevicesActiveTab: index } }));
    } else {
      throw new Error('unknown devices');
    }
  };

  const devicesTypeTitleForBtn = useMemo(() => getAddTitle(type), [type]);

  const omitDevicePassword = (fields) => {
    const newFields = { ...fields };
    const oldFields = { ...sensors?.data.find(({ _id }) => _id === newFields.id) };
    if (
      newFields?.data?.Password
      && oldFields?.data?.Password
      && newFields.data.Password === oldFields.data.Password
    ) {
      const data = omit(newFields.data, ['Password']);
      return { ...newFields, data };
    }
    return newFields;
  };

  const getNewTags = () => dispatch(dataListReloadData({ listID: tagsListID }));

  const openAddDevices = (newType) => {
    if ('numberOfAllowedAitHeatPumps' in (license.restrictions || {})) {
      if (type === 'devices' && total >= license.restrictions.numberOfAllowedAitHeatPumps) {
        dispatch(openModalWindow({ modalID: UPGRADE_TO_UNLOCK_POPUP }));
        return;
      }
    }

    getNewTags();
    dispatch(
      openModalWindow({
        modalID: ADD_DEVICE_MODAL_ID,
        data: !['smart-meter'].includes(type)
          ? null
          : {
            newType,
            newTitle: getAddTitle(newType)
          }
      })
    );
  };

  const addDeviceRequest = (fields) => {
    const devicesUrls = {
      loadguards: `${LOADGUARDS_URL}/${userId}`,
      'sub-meter': `${SENSORS_SUB_METER_URL}/${myRoleType === 'end_user' ? 'my' : userId}`,
      'smart-meter': `${SENSORS_URL}/smart-meter/${userId}`,
      default: `${SENSORS_URL}/${type}/${userId}`
    };

    const devicesUrlAdd = devicesUrls?.[getModalsAddDeviceData?.newType === 'sub-meter' ? 'sub-meter' : type]
      || devicesUrls.default;

    return dispatch(
      modalWindowSend({
        modalID: ADD_DEVICE_MODAL_ID,
        requestConfig: {
          method: 'post',
          url: devicesUrlAdd,
          data: transformFieldsForAPI(
            omitFakeField({ ...fields, color: fields?.device_color || randomColor }, type),
            type
          )
        },
        cb: () => {
          dispatch(getNewDevices());

          return updateSettings();
        }
      })
    );
  };

  const removeDeviceRequest = ({ id, url }) => {
    const devicesUrls = {
      loadguards: LOADGUARDS_URL,
      'sub-meter': `${SENSORS_SUB_METER_URL}/${myRoleType === 'end_user' ? 'my' : userId}`,
      'smart-meter': `${SENSORS_URL}/smart-meter/${userId}`,
      cars: `${SENSORS_URL}/car/${userId}`,
      default: `${SENSORS_URL}/${type}/${userId}`
    };
    const isSubSensor = subSensors?.some((sensor) => sensor._id === id);
    const devicesUrlRemove = devicesUrls?.[isSubSensor ? 'sub-meter' : type] || devicesUrls.default;

    return dispatch(
      modalWindowSend({
        modalID: REMOVE_DATA_MODAL_ID,
        requestConfig: {
          method: 'delete',
          url: `${url || devicesUrlRemove}/${id}`
          // isSubSensor: isSubSensor && subMetersUrl
        },
        cb: () => {
          dispatch(type === 'cars' ? dataListReloadData({ listID: 'cars' }) : getNewDevices());

          return updateSettings();
        }
      })
    );
  };

  const editDeviceRequest = (fields) => {
    const devicesUrls = {
      loadguards: LOADGUARDS_URL,
      'sub-meter': `${SENSORS_SUB_METER_URL}/${myRoleType === 'end_user' ? 'my' : userId}`,
      'smart-meter': `${SENSORS_URL}/smart-meter/${userId}`,
      default: `${SENSORS_URL}/${type}/${userId}`
    };
    const isSubSensor = subSensors?.some((sensor) => sensor._id === fields?.id);

    const devicesUrlEdit = devicesUrls?.[isSubSensor ? 'sub-meter' : type] || devicesUrls.default;
    const omitFields = flowRight(omitDevicePassword, omitFakeField);

    if (!isDisabledOauth2EditModal) {
      dispatch(sensorsOauth2AllowedToDisabledEditModal({ data: true }));
    }

    return dispatch(
      modalWindowSend({
        modalID: EDIT_ROW_MODAL_ID,
        requestConfig: {
          method: 'put',
          url:
            type === 'loadguards'
              ? `${devicesUrlEdit}/${fields.loadGuardDeviceId}`
              : `${devicesUrlEdit}/${fields.id}`,
          data: transformFieldsForAPI(
            omitFields({ ...fields, color: fields?.device_color }),
            type
          ),
          isSubSensor: isSubSensor && subMetersUrl
        },
        cb: getNewDevices
      })
    );
  };

  // eslint-disable-next-line arrow-body-style
  const submitRemoveSubUser = ({ id, currentSubMeterId }) => {
    return dispatch(
      modalWindowSend({
        modalID: REMOVE_SUB_USER_MODAL_ID,
        requestConfig: {
          method: 'DELETE',
          url: `${USERS_SUB_USER_URL}/${id}/sensor`,
          headers: { 'Content-Type': 'multipart/form-data' }
        },
        cb: () => getSubUserReloadData({ listID: dataListID, parentIdOfSubUsers: currentSubMeterId })
      })
    );
  };

  // eslint-disable-next-line arrow-body-style
  const submitInviteSubUser = ({ email, parentSubMeter }) => {
    return dispatch(
      modalWindowSend({
        modalID: INVITE_SUB_USER_MODAL_ID,
        requestConfig: {
          method: 'post',
          url: USERS_SUB_USER_URL,
          data: {
            email,
            sensor: parentSubMeter,
            parentEndUser: userDevicesUser_Id
          }
        },
        cb: () => getSubUserReloadData({ listID: dataListID, parentIdOfSubUsers: parentSubMeter })
      })
    );
  };

  // eslint-disable-next-line arrow-body-style
  const submitCarChargerModal = (data, id, deviceType, rowData) => {
    const carUpdUrl = myRoleType === 'end_user' ? `/sensors/car/my/${rowData._id}` : `/sensors/car/${id}/${rowData._id}`;
    const url = deviceType === 'Car' ? carUpdUrl : `sensor/${id}`;

    const carChargers = deviceType === 'Car'
      ? {
        device_type: rowData.device_type,
        type: rowData.type,
        device_group: rowData.device_group,
        carChargers: data
      }
      : { car_charger: data };

    return dispatch(
      modalWindowSend({
        modalID: ADD_CAR_CHARGERS_WINDOW_MODAL_ID,
        requestConfig: {
          method: 'put',
          url,
          data: carChargers
        },
        cb: () => dataListReloadData({ listID: deviceType === 'Car' ? 'cars' : 'sub-meter' })
      })
    );
  };

  const generalTabProps = {
    userId,
    myRoleType,
    total,
    isReadOnlyPermission: isReadOnlyPermission || isPvEmployeeInstallHasReadOnlyAccess,
    transformResponse,
    defaultParams,
    ...(type !== 'loadguards' && type !== 'smart-meter'
      ? {
        openAddDevices,
        devicesUrl
      }
      : {}),
    widthSetting: userDetailsSettings.width || {},
    columnsSetting: userDetailsSettings.columns || {},
    license: userLicense
  };

  return (
    <>
      <DataList listID={tagsListID} listURL={tagsListURL} forceKeepData />
      <ChangeColumnsModalWindow dataListID="userDetails" page="devices" />
      <InviteSubUser handleSubmitHandler={submitInviteSubUser} />
      <AddCarChargersWindow handleSubmit={submitCarChargerModal} />
      <CommonInfoModalWindow
        modalID={EXPERT_MODAL_ID}
        label="expertModeTitle"
        info="expertModeInfo"
      />
      <CommonInfoModalWindow
        modalID={ADDITIONAL_COST_MODAL_ID}
        isShowEachTime
        label="additionalCostTitle"
      />
      <DeleteDeviceWindow onSubmit={removeDeviceRequest} />
      <RemoveSubUser onSubmit={submitRemoveSubUser} />
      <RemoveCarCharger onSubmit={submitCarChargerModal} />
      <AddCar />
      <EditCar />
      <ColorPickerModalWindow />
      <SearchIPWindow />
      <SearchChargersWindow />
      <SkiSearchWindow />
      <BatteryModeModalWindow />

      <AddDeviceWindowHoc
        onSubmit={addDeviceRequest}
        deviceType={type}
        randomColor={randomColor}
        title={devicesTypeTitleForBtn}
        license={license}
        userApp={userApp}
        userId={userId}
        tariffSettings={tariffSettings}
        newBatteryMode={newBatteryMode}
      />

      <EditDeviceWindowHoc
        onSubmit={editDeviceRequest}
        deviceType={type}
        enableReinitialize
        initialValues={editModalData}
        license={license}
        userApp={userApp}
        userId={userId}
        tariffSettings={tariffSettings}
        newBatteryMode={newBatteryMode}
      />

      <div className="container-fluid">
        <div className="table-devices-body">
          <Tabs selectedIndex={userDetailsSettings.userDevicesActiveTab || 0} onSelect={changeType}>
            <TabList className={`react-tabs__tab-list ${type}`}>
              <Tab>
                <TabContentContainer>{i18n.t('devices')}</TabContentContainer>
              </Tab>
              <Tab>
                <TabContentContainer>{i18n.t('inverters')}</TabContentContainer>
              </Tab>
              <Tab>
                <TabContentContainer>{i18n.t('Smart Meter')}</TabContentContainer>
              </Tab>
              <Tab>
                <TabContentContainer>{i18n.t('cars')}</TabContentContainer>
              </Tab>
              <Tab>
                <TabContentContainer>{i18n.t('inputDevices')}</TabContentContainer>
              </Tab>
              <Tab className="react-tabs__tab load-guard-tab-item">
                <TabContentContainer className="load-guard-tab-container">
                  {i18n.t('loadGuard')}
                  <Tooltip
                    title={(
                      <a className="zev-zoho-link" href={ZEV_ZOHO_LINK} target="_blank" rel="noopener noreferrer">
                        {ZEV_ZOHO_LINK}
                      </a>
                    )}
                    className="load-guard-tooltip"
                    placement="right"
                    style={{
                      color: 'var(--primary-color)'
                    }}
                  />
                </TabContentContainer>
              </Tab>
            </TabList>

            <TabPanel>
              <DevicesTab {...generalTabProps} />
            </TabPanel>

            <TabPanel>
              <InvertersTab {...generalTabProps} />
            </TabPanel>

            <TabPanel>
              <SmartMeterTab
                {...generalTabProps}
                devicesUrl={smartMeterUrl}
                subMetersUrl={subMetersUrl}
                openAddDevices={openAddDevices}
              />
            </TabPanel>

            <TabPanel>
              <CarsTab {...generalTabProps} />
            </TabPanel>

            <TabPanel>
              <InputDevicesTab {...generalTabProps} />
            </TabPanel>

            <TabPanel>
              <LoadGuardTab
                {...generalTabProps}
                loadGuardUrl={loadGuardUrl}
                getNewTags={getNewTags}
              />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </>
  );
};

DevicesTable.propTypes = {
  myRoleType: PropTypes.string,
  userId: PropTypes.string,
  userApp: PropTypes.string,
  license: PropTypes.instanceOf(Object)
};

export default DevicesTable;
