import fieldTypes from '../containers/DevicesTable/utils/fieldTypes.json';

const formattersByDeviceType = {
  string: (value) => String(value)?.trim() ?? '',
  int: (value) => parseInt(value, 10),
  bool: (value) => Boolean(value),
  float: (value) => parseFloat(String(value)?.replace(',', '.'))
};

export const formatDataByDeviceType = (data = {}) => {
  const formattedData = {};

  Object.keys(data).forEach((key) => {
    if (fieldTypes[key] === 'object' && !Array.isArray(data) && data !== null) {
      formattedData[key] = formatDataByDeviceType(data[key]);
    } else if (Array.isArray(data)) {
      formattedData[key] = data.map((item) => formatDataByDeviceType(item));
    } else {
      formattedData[key] = formattersByDeviceType[fieldTypes[key]]?.(data[key]) ?? data[key];
    }
  });

  return formattedData;
};
