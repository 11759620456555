import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  chart: {},
  activeDevices: {}
};

const heatPumpMonitoringSlice = createSlice({
  name: 'heatPumpMonitoring',
  initialState,
  reducers: {
    updateChart: (state, action) => {
      state.chart = { ...state.chart, ...action.payload };
    },

    updateActiveDevices: (state, action) => {
      state.activeDevices = action.payload;
    },
    resetState: () => initialState
  }
});

// eslint-disable-next-line no-empty-pattern
export const { updateChart, updateActiveDevices, resetState } = heatPumpMonitoringSlice.actions;

export default heatPumpMonitoringSlice.reducer;
