import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../../../api_helper';
import { settingPrGetUserSucc } from '../../SettingProfile/slice';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: axiosBaseQuery,
  tagTypes: [],
  endpoints: (builder) => ({
    getUserFromQuery: builder.query({
      providesTags: ['userQuery'],
      refetchOnMountOrArgChange: true,
      keepUnusedDataFor: 0,
      query: ({ userID, mySelf }) => ({
        url: mySelf
          ? '/user/me?isWeb=true'
          : `/users/solar-managers-users/${userID}`,
        method: 'get'
      }),

      onQueryStarted: async (queryArgs, { queryFulfilled, dispatch }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(settingPrGetUserSucc({ initialData: data, myself: queryArgs.mySelf }));
        } catch (error) {
          console.log('userApi getUserFromQuery', error);
        }
      }
    })
  })
});

export const {
  useGetUserFromQueryQuery
} = userApi;
