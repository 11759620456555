import { createApi } from '@reduxjs/toolkit/query/react';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { axiosBaseQuery } from '../../../api_helper';
import { parseData, updatedActiveDevices as updatedActiveDevicesParser } from '../../DashboardV2/utils/activeDevices';
import { addRealTimeDataPoints, formatChartData } from '../utils';
import { addNotification } from '../../NotificationGenerator/slice';
import { updateActiveDevices, updateChart } from '../slice';

export const HeatPumpMonitoringApi = createApi({
  tagTypes: ['Post', 'User'],
  reducerPath: 'HeatPumpMonitoringApi',
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getHeatPumpCockpitData: builder.query({
      refetchOnMountOrArgChange: true,
      keepUnusedDataFor: 0,
      query: ({ userID, from, to, heatpumpSensorId }) => {
        const params = new URLSearchParams({ from, to });
        if (heatpumpSensorId) params.append('heatpumpSensorId', heatpumpSensorId);
        return {
          url: `/data/heatpump-cockpit-points/${userID}?${params.toString()}`,
          method: 'get'
        };
      },
      transformResponse: ({ data, interval, from, to }) => ({
        mainChart: {
          powers: formatChartData(data.powerKeys, data.powers),
          powerKeys: data.powerKeys,
          powerKeysNames: data.powerKeysNames,
          temperatures: formatChartData(data.temperatureKeys, data.temperatures),
          temperatureKeys: data.temperatureKeys,
          temperatureKeysNames: data.temperatureKeysNames
        },
        statusChart: {
          // main: formatChartData(data.mainKeys, data.main),
          // mainKeys: data.mainKeys,
          // mainKeysNames: data.mainKeysNames,
          others: formatChartData(data.otherKeys, data.others),
          othersKeys: data.otherKeys,
          othersKeysNames: data.otherKeysNames,
          booleans: formatChartData(data.booleanKeys, data.booleans),
          booleanKeys: data.booleanKeys,
          booleanKeysNames: data.booleanKeysNames
        },
        interval,
        from,
        to
      }),
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(updateChart({ ...data, realTimeScaleData: undefined }));
        } catch (error) {
          dispatch(addNotification({ type: 'error', text: error.message }));
        }
      }
    }),
    getActiveDevices: builder.query({
      refetchOnMountOrArgChange: true,
      keepUnusedDataFor: 0,
      query: ({ gatewayId, from, to, sensorId }) => {
        const params = new URLSearchParams({ gatewayId, from, to, sensorId });
        return { url: `/dashboard/active-devices?${params.toString()}`, method: 'get' };
      },
      transformResponse: (devices, _meta, args) => parseData(devices, args.from.valueOf(), args.to.valueOf(), args.settings || {}),
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateActiveDevices(data));
        } catch ({ error }) {
          dispatch(addNotification({ type: 'error', text: error.message }));
        }
      }
    }),
    getHeatPumpCockpitRealTimeData: builder.query({
      refetchOnMountOrArgChange: true,
      keepUnusedDataFor: 0,
      providesTags: ['User'],
      invalidatesTags: ['User'],
      query: ({ userID, heatpumpSensorId, startOfSlice, to }) => {
        const params = new URLSearchParams({ from: moment(startOfSlice).toISOString(), to: moment(to).toISOString() });

        if (heatpumpSensorId) params.append('heatpumpSensorId', heatpumpSensorId);
        return { url: `/data/heatpump-cockpit-points/${userID}?${params.toString()}`, method: 'get' };
      },
      transformResponse: ({ data }) => ({
        activity: data.activity,
        mainChart: {
          powers: formatChartData(data.powerKeys, data.powers),
          temperatures: formatChartData(data.temperatureKeys, data.temperatures)
        },
        statusChart: {
          //  main: formatChartData(data.mainKeys, data.main),
          others: formatChartData(data.otherKeys, data.others),
          booleans: formatChartData(data.booleanKeys, data.booleans)
        }
      }),
      async onQueryStarted(args, { queryFulfilled, dispatch, getState }) {
        try {
          const { data: realTimeData } = await queryFulfilled;
          const { chart: initialChartData, activeDevices } = getState().heatPumpMonitoring;
          const initialChartDataCopy = cloneDeep(initialChartData);

          const { from, to } = args;
          const updatedActiveDevices = updatedActiveDevicesParser(
            moment(to).valueOf(),
            realTimeData.activity,
            moment(from).valueOf(),
            cloneDeep(activeDevices.activity || []),
            cloneDeep(activeDevices.devices || [])
          );

          addRealTimeDataPoints(initialChartDataCopy, realTimeData);
          dispatch(updateActiveDevices(updatedActiveDevices));

          initialChartDataCopy.realTimeScaleData = { from: moment(from).valueOf(), to: moment(to).valueOf() };

          dispatch(updateChart(initialChartDataCopy));
        } catch (error) {
          dispatch(addNotification({ type: 'error', text: error.message }));
        }
      }
    })
  })
});

export const {
  useGetHeatPumpCockpitDataQuery,
  useGetActiveDevicesQuery,
  useLazyGetHeatPumpCockpitRealTimeDataQuery
} = HeatPumpMonitoringApi;
