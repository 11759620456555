import { isArray, isPlainObject } from 'lodash';

/**
 * Pushes the realTimeData into the initialChartData object
 * @param {Object} initialChartData
 * @param {Object} realTimeData
 */
export const addRealTimeDataPoints = (initialChartData, realTimeData) => {
  Object.keys(realTimeData).forEach((key) => {
    if (isArray(initialChartData[key])) {
      initialChartData[key].push(...realTimeData[key]);
    }

    if (isPlainObject(initialChartData[key])) {
      addRealTimeDataPoints(initialChartData[key], realTimeData[key]);
    }
  });
};
