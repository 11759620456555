import { createSelector } from '@reduxjs/toolkit';
import { getChartUser } from '../../redux-store/selectors/loadManagementDashboard';
import { getDashboardUser } from '../../redux-store/selectors/dashboard';

import { getSignInUser } from '../../redux-store/selectors/signIn';
import { getSettingProfileInitialData } from '../../redux-store/selectors/settingProfile';

export const linkForLogoSelector = createSelector(
  [getSignInUser, getDashboardUser, getChartUser, getSettingProfileInitialData],
  (user, initialData, chartUser, userFromParams) => {
    switch (user?.role?.type) {
      case 'oem':
        if (user?.company_logo) {
          return user?.oem?.link_for_logo;
        }
        break;
      case 'pv_installer':
        if (user?.company_logo) {
          return user?.my_company?.link_for_logo;
        }
        break;
      case 'solar_admin':
      case 'property_management':
      case 'root':
      case 'viewer':
      case 'oem_employee':
      case 'oem_employee_read_only':
        return user?.employerOem?.link_for_logo || initialData?.connectedOem?.link_for_logo
          || initialData?.company?.link_for_logo
          || chartUser?.connectedOem?.link_for_logo
          || chartUser?.company?.link_for_logo
          || userFromParams?.company?.link_for_logo
          || userFromParams?.connectedOem?.link_for_logo;
      default:
        if (user?.oem_company_logo) {
          return user?.connectedOem?.link_for_logo;
        }
        if (user?.company_logo) {
          return user?.company?.link_for_logo;
        }
        break;
    }
    return undefined;
  }
);
