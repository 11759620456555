import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Box } from '@mui/material';
import TextField from './TextField';
import { additionalFields } from '../../../utils';

const ManualMode = () => (
  <>
    <Field {...additionalFields.batteryManualMode} name="batteryManualMode" />
    <Box className="field-wrapper" sx={{ marginTop: '12px' }}>
      <TextField {...additionalFields.lowerSocLimit} name="lowerSocLimit" />
      <TextField {...additionalFields.upperSocLimit} name="upperSocLimit" />
      <TextField {...additionalFields.powerCharge} name="powerCharge" />
      <TextField {...additionalFields.powerDischarge} name="powerDischarge" />
    </Box>
  </>
);
ManualMode.propTypes = {
  formData: PropTypes.instanceOf(Object).isRequired,
  formID: PropTypes.string.isRequired
};

export default ManualMode;
