/**
 * Format the data to be used in the chart
 * @param {Array} keys
 * @param {Array} data
 * @returns {Object}
 */

export const formatChartData = (keys, data) => {
  if (!keys.length || !data.length) {
    return {};
  }

  // If there's only one key for data, return it directly.
  if (keys.length === 2) {
    return { [keys[1]]: data.map((row) => [row[0], row[1]]) };
  }

  // For multiple keys, map data to an object
  return keys.slice(1).reduce((acc, key, index) => {
    acc[key] = data.map((row) => [row[0], row[index + 1]]);
    return acc;
  }, {});
};
