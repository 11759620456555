import moment from 'moment-timezone';

const hourInMS = 3600000;
const weekInMS = 604800000;

export const getRange = (scaleType, chart) => {
  const now = moment().valueOf();
  let from = moment(now);
  let to = moment(now);

  switch (scaleType) {
    case 't':
      from = moment(now).startOf('day');
      break;
    case 'h':
      from = moment(now).subtract(1, 'hours');
      break;
    case 'd':
      from = moment(now).subtract(1, 'days');
      break;
    case 'w':
      from = moment(now).startOf('week');
      break;
    case 'back': {
      from = moment(chart.from).subtract(Math.round(chart.scaleMS), 'ms');
      to = moment(from).add(chart.scaleMS, 'ms');
      break;
    }
    case 'forward': {
      to = moment(chart.to).add(Math.round(chart.scaleMS), 'ms');
      from = moment(to).subtract(chart.scaleMS, 'ms');
      break;
    }
    default: {
      if (typeof scaleType === 'number') {
        let newScale = chart.scaleMS * scaleType;
        if (newScale < hourInMS) {
          newScale = hourInMS;
        } else if (newScale > weekInMS) {
          newScale = weekInMS;
        }
        const offset = (chart.scaleMS - newScale) / 2;
        from = moment(chart.from).add(offset, 'ms');
        to = moment(chart.to).subtract(offset, 'ms');
      }
    }
  }

  return { from: from.toISOString(), to: to.toISOString() };
};
export const getScaleRange = ({ from, to, type }, chartData) => {
  if (from && to) {
    return {
      from: moment(Number(from)).toISOString(),
      to: moment(Number(to)).toISOString()
    };
  }

  return getRange(type || 't', chartData);
};
