import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, getFormValues, initialize, change, destroy, Field } from 'redux-form';
import { createSelector } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { keyBy } from 'lodash';
import ModalWindow from '../../ModalWindow';
import { TariffFormCommonContent } from './TariffFormCommonContent';
import { closeModalWindow } from '../../ModalWindow/slice';
import { CustomDateInput } from '../../../components/ReduxFormFields';
import { required } from '../../validation';

import i18n from '../../../i18n';
import { ReactComponent as ArrLeftSVG } from '../../../assets/images/svg/arrow-left.svg';

import { HiSTORICAL_TARIFF_LIST_ID, TARIFFS_LIST_ID } from '../constants';

const modalSelector = createSelector(
  (state) => state.modals[HiSTORICAL_TARIFF_LIST_ID],
  (modal) => modal
);

const tariffsSelector = createSelector(
  (state) => state.dataList?.[TARIFFS_LIST_ID]?.data || [],
  (tariffs) => [...tariffs]?.sort((a, b) => moment(a.from).diff(moment(b.from)))
    .map((item, index, arr) => ({
      ...item,
      from: moment(item.from).valueOf(),
      to: moment(arr[index + 1]?.from).valueOf()
    }))
);

const formSelector = createSelector(
  getFormValues(HiSTORICAL_TARIFF_LIST_ID),
  (formValues) => formValues || {}
);

const HistoricalTariffWindow = ({
  handleSubmit,
  myRoleType,
  initialValues,
  unit,
  user,
  valid,
  onDelete,
  pristine
}) => {
  const dispatch = useDispatch();
  const modal = useSelector(modalSelector);
  const tariffs = useSelector(tariffsSelector);
  const formValues = useSelector(formSelector);

  const { isNew, tariffId } = modal?.data || {};

  const {
    importEnergy: { tariffType: tariffTypeImport } = {},
    exportEnergy: { tariffType: tariffTypeExport } = {},
    activeTab,
    isHelionUser
  } = formValues || {};

  const objTariffs = keyBy(tariffs, '_id');

  // historical tariffs can't have the same from date or future date as the current tariff
  const currentTariff = tariffs?.find((tariff) => tariff.state === 'active');
  const currentTariffFrom = moment(currentTariff?.from);

  const validateFrom = useCallback((value) => {
    if (!moment(value).isValid()) {
      return i18n.t('invalidDateFormatValidationError');
    }

    if (moment(value).isSameOrAfter(currentTariffFrom, 'day')) {
      return i18n.t('invalidDateFormatValidationError');
    }

    if (tariffs.find((tariff) => moment(tariff.from).isSame(value, 'day'))) {
      return i18n.t('invalidDateFormatValidationError');
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew, tariffId]);

  const modalTitle = isNew ? i18n.t('addTariff') : `${moment(objTariffs[tariffId]?.from).format('MMM. DD, YYYY')} - ${moment(objTariffs[tariffId]?.to).subtract(1, 'day').format('MMM DD, YYYY')}`;

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: HiSTORICAL_TARIFF_LIST_ID }));

  const onTabSelect = (index) => {
    if (valid) dispatch(change(HiSTORICAL_TARIFF_LIST_ID, 'activeTab', index));
  };

  useEffect(() => {
    if (modal?.opened && formValues.from && moment(formValues.from).isValid()) {
      const nextTariff = tariffs.find((tariff) => moment(formValues.from).isBetween(moment(tariff.from), moment(tariff.to).subtract(1, 'day'), 'day', '[]'));

      if (nextTariff) {
        dispatch(change(HiSTORICAL_TARIFF_LIST_ID, 'to', moment(nextTariff.to).subtract(1, 'day').valueOf()));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.from]);

  useEffect(() => {
    if (modal?.opened && tariffId && objTariffs[tariffId]) {
      dispatch(initialize(HiSTORICAL_TARIFF_LIST_ID, {
        ...initialValues,
        ...objTariffs[tariffId],
        importEnergy: {
          ...initialValues?.importEnergy,
          ...objTariffs[tariffId]?.importEnergy
        },
        exportEnergy: {
          ...initialValues?.exportEnergy,
          ...objTariffs[tariffId]?.exportEnergy
        },
        ...(initialValues.isHelionUser ? { helionExportEnergy: objTariffs[tariffId].exportEnergy } : {}),
        saveAsNewTariff: false,
        from: moment(objTariffs[tariffId].from).startOf('day').valueOf(),
        to: moment(objTariffs[tariffId].to).subtract(1, 'day').valueOf()
      }));
    }

    if (modal?.opened && isNew) {
      dispatch(initialize(HiSTORICAL_TARIFF_LIST_ID, {
        ...initialValues,
        from: moment(initialValues.from).startOf('day').subtract(2, 'day').valueOf(),
        to: moment().subtract(1, 'day').valueOf()
      }));
    }

    return () => dispatch(destroy(HiSTORICAL_TARIFF_LIST_ID));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened, isNew, tariffId]);

  return (
    <ModalWindow
      modalID={HiSTORICAL_TARIFF_LIST_ID}
      customStyle={{ zIndex: 1002 }}
    >

      <div
        className="modal-header"
        style={{
          padding: '30px 40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >

        <h5
          className="modal-title"
          style={{
            margin: 0,
            display: 'flex',
            alignItems: 'center',
            fontSize: '18px',
            textTransform: 'uppercase'
          }}
        >
          <button type="button" className="button button-third pr-2 pl-2" onClick={handleOnClose}>
            <ArrLeftSVG />
          </button>
          {modalTitle}
        </h5>
      </div>
      <div className="modal-body">
        <form
          id={HiSTORICAL_TARIFF_LIST_ID}
          onSubmit={handleSubmit}
          className="m-login__form m-form pop-up-form edit-tariff-form"
        >
          <Box
            className="date-picker"
            sx={{ position: 'relative', pb: 2, display: 'flex', gap: '24px' }}
          >
            <Box>
              <Typography
                className="inputLabel"
                variant="h6"
              >
                {i18n.t('from.label')}
              </Typography>
              <Field
                name="from"
                className="m-input"
                autoComplete="off"
                preventAuto
                isMui
                component={CustomDateInput}
                validate={[required, validateFrom]}
              />
            </Box>
            <Box>
              <Typography
                className="inputLabel"
                variant="h6"
              >
                {i18n.t('to.label')}
              </Typography>
              <Field
                name="to"
                preventAuto
                disabled
                className="m-input"
                autoComplete="off"
                isMui
                component={CustomDateInput}
              />
            </Box>
          </Box>
          <TariffFormCommonContent
            type="historic"
            formValues={formValues}
            myRoleType={myRoleType}
            unit={unit}
            onTabSelect={onTabSelect}
            activeTab={activeTab}
            valid={valid}
            initialValues={initialValues}
            user={user}
            tariffTypeImport={tariffTypeImport}
            tariffTypeExport={tariffTypeExport}
            isHelionUser={isHelionUser}
            formID={HiSTORICAL_TARIFF_LIST_ID}
          />
        </form>
      </div>
      <div
        className="modal-footer highlighted"
        style={{ gap: '24px' }}
      >
        {!isNew && (
          <div className="w-100">
            <button
              type="button"
              className="button button-delete"
              form={HiSTORICAL_TARIFF_LIST_ID}
              onClick={() => onDelete(tariffId)}
            >
              {i18n.t('delete')}
            </button>
          </div>
        )}
        <button
          type="button"
          className="button button-secondary"
          form={HiSTORICAL_TARIFF_LIST_ID}
          onClick={handleOnClose}
        >
          {i18n.t('cancel')}
        </button>
        <button
          type="submit"
          disabled={!valid || pristine}
          className="button button-primary"
          form={HiSTORICAL_TARIFF_LIST_ID}
        >
          {i18n.t(isNew ? 'addTariff' : 'save')}
        </button>
      </div>
    </ModalWindow>
  );
};

const form = reduxForm({
  form: HiSTORICAL_TARIFF_LIST_ID
})(HistoricalTariffWindow);

export default form;

HistoricalTariffWindow.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  myRoleType: PropTypes.string.isRequired,
  initialValues: PropTypes.instanceOf(Object).isRequired,
  unit: PropTypes.string.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  valid: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired
};
