import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _, { omit } from 'lodash';

import { AdditionalDataListFilters, SearchField, Pagination, TopBar, Breadcrumbs, Expander } from '../../components';
import { DataListPagination, DataListFilterV2 } from '../DataList';
import { PrimaryButton } from '../../components/UIKit';
import { UserInfoSub } from '../../components/ReactTable/renderFunctions';
import { AddUserWindow, AddPropMgmtModal } from './modals';
import { openModalWindow, modalWindowSend, ADD_DATA_MODAL_ID, REMOVE_DATA_MODAL_ID, closeModalWindow } from '../ModalWindow/slice';
import { dataListReloadData, dataListSendRequest } from '../DataList/slice';
import { getPagesSettingsSelector } from '../../redux-store/selectors/pagesSettings';
import { visibleWebSelector } from './selectors';
import columns from './columns';
import { dataListID, ADD_USER_TO_PROPERTY_MANAGEMENT } from './constants';
import { USERS_SOLAR_MANAGERS_USERS_URL, USERS_URL } from '../../api/apiUrls';
import { ChangeColumnsModalWindow, DeleteEndUserModalWindow, ConfirmDeleteUserWindow, CommonInfoModalWindow } from '../../modals';
import { setPagesSetting, setPagesSettingQueries } from '../PagesSettings/slice';
import { email } from '../validation';
import i18n from '../../i18n';
import './index.scss';
import { ReactTableV2 } from '../../components/ReactTableV2';

/**
 * @module UsersTable
 */
const transformResponse = (data) => {
  const clonedData = _.clone(data.list);
  const user = {
    first_name: 'Unknown User',
    last_name: '',
    sm_id: 'Unknown Id',
    id: 'Unknown Id',
    _id: 'Unknown Id'
  };

  clonedData.forEach((obj) => {
    if (!obj.user) {
      obj.user = user;
    }
  });

  return {
    total: data.total,
    data: clonedData
  };
};

/**
 * Renders page for list of End users
 * @memberof module:UsersTable
 */
const UsersTable = (props) => {
  const { myRoleType } = props;
  const dispatch = useDispatch();

  const { [dataListID]: pageSettings } = useSelector(getPagesSettingsSelector);
  const userColumns = useMemo(() => columns(myRoleType, pageSettings), [myRoleType, pageSettings]);
  const visibleWeb = useSelector(visibleWebSelector);
  const defaultParams = pageSettings.queries;
  const { isFilteredTabExpanded } = pageSettings;

  const limitPermissionRoles = ['viewer', 'pv_installer_employee_read_only', 'oem_employee_read_only', 'property_management'];

  const sendAddUser = (fields) => {
    if (myRoleType === 'pv_installer' && !fields.connectedOem) {
      delete fields.sendOemTemplate;
    }

    const updatedFields = (fields?.pv_installer && fields.pv_installer === 'null' && !['pv_installer', 'pv_installer_employee'].includes(myRoleType))
      ? _.omit(fields, ['pv_installer', 'connectedOem'])
      : { ...fields };

    if (['property_management'].includes(myRoleType)) {
      updatedFields.sendOemTemplate = false;

      if (email(updatedFields.email) !== undefined) {
        updatedFields.userName = updatedFields.email;
        updatedFields.email = undefined;
      }
    }

    //! if validation failed we show warning modal about prop management flow
    if (!['property_management'].includes(myRoleType) && email(updatedFields.email) !== undefined) {
      return dispatch(openModalWindow({ modalID: 'propMgmtWarning', data: null }));
    }

    if (updatedFields.email) {
      updatedFields.email = updatedFields.email.toLowerCase();
    }

    return dispatch(modalWindowSend({
      modalID: ADD_DATA_MODAL_ID,
      requestConfig: {
        method: 'post',
        url: USERS_SOLAR_MANAGERS_USERS_URL,
        data: updatedFields
      },
      cb: () => dataListReloadData({ listID: dataListID })
    }));
  };

  const sendAddUserToPropMgmt = (fields) => {
    dispatch(modalWindowSend({
      modalID: ADD_DATA_MODAL_ID,
      requestConfig: {
        method: 'post',
        url: USERS_SOLAR_MANAGERS_USERS_URL,
        data: fields
      },
      keepOpenIfError: true,
      finally: () => dataListReloadData({ listID: dataListID }),
      cb: () => dispatch(closeModalWindow({ modalID: ADD_USER_TO_PROPERTY_MANAGEMENT }))
    }));
  };

  const sendRemoveUser = ({ id }) => dispatch(modalWindowSend({
    modalID: REMOVE_DATA_MODAL_ID,
    requestConfig: {
      method: 'delete',
      url: `/user/${id}`
    },
    cb: () => dataListReloadData({ listID: dataListID })
  }));

  const onClickHandler = () => dispatch(openModalWindow({ modalID: ADD_DATA_MODAL_ID, data: null }));

  const handleExpandedFilterChange = () => {
    dispatch(setPagesSetting({ page: dataListID, data: { isFilteredTabExpanded: !isFilteredTabExpanded } }));
    if (isFilteredTabExpanded) {
      const paramsForReset = omit(pageSettings.queries, ['_sort', '_limit', '_start', 'fields', 'search']);
      const reset = Object.keys(paramsForReset).reduce((acc, key) => ({ ...acc, [key]: undefined }), {});
      dispatch(dataListSendRequest(
        {
          listID: dataListID,
          dataList: {
            listURL: USERS_URL,
            params: { ...defaultParams, ...reset },
            transformResponse
          }
        }
      ));
      dispatch(setPagesSettingQueries({ page: dataListID, data: reset }));
    }
  };

  const handleConfirmAddingEndUserToPropMgmt = () => {
    dispatch(openModalWindow({ modalID: ADD_USER_TO_PROPERTY_MANAGEMENT, data: null }));
    dispatch(closeModalWindow({ modalID: 'propMgmtWarning' }));
  };

  return (
    <div className="main-table-container">
      {!limitPermissionRoles.includes(myRoleType) ? (
        <AddUserWindow
          onSubmit={sendAddUser}
          initialValues={{
            sendOemTemplate: ['oem', 'oem_employee'].includes(myRoleType)
          }}
        />
      ) : null}
      <CommonInfoModalWindow
        modalID="propMgmtWarning"
        label="attention"
        info="propManagementFlowWarning"
        cancelLabel="cancel"
        confirmLabel="accept"
        confirmHandler={handleConfirmAddingEndUserToPropMgmt}
      />

      {['root', 'solar_admin'].includes(myRoleType) && (
        <>
          <DeleteEndUserModalWindow onSubmit={sendRemoveUser} />
          <ConfirmDeleteUserWindow userRole={i18n.t('eUser').toLowerCase()} />
        </>
      )}

      {['root', 'solar_admin', 'pv_installer', 'pv_installer_employee', 'oem', 'oem_employee'].includes(myRoleType) && (
        <AddPropMgmtModal onSubmit={sendAddUserToPropMgmt} />
      )}

      <ChangeColumnsModalWindow dataListID={dataListID} />

      <TopBar
        updateButton
        showNavigation
        visibleWeb={visibleWeb}
      />
      <div className="container-fluid px-0">
        <Breadcrumbs breadcrumbsClass="px-4" crumbs={[{ name: i18n.t('SMUs') }]} />

        <div className="m-form m-form--label-align-right m--margin-bottom-20">
          <div className="col-xl-12 px-0">
            <div className="top-control-tab row align-items-center mb-3 mx-0 px-4">
              <div className="col-xl-4 col-sm-7 col-lg-4 col-md-5 col-8 order-1 ">
                <DataListFilterV2
                  listURL={USERS_URL}
                  Component={SearchField}
                  componentProps={{
                    defaultValue: pageSettings?.queries?.search,
                    searchByFields: 'sm_id,email,first_name,last_name,company,street,city,zip,plant',
                    placeholder: i18n.t('searchAllTablesElements')
                  }}
                  listID={dataListID}
                  transformResponse={transformResponse}
                />
              </div>
              <div className="col-4 col-lg-2 order-2 mr-auto">
                <button
                  type="button"
                  onClick={handleExpandedFilterChange}
                  className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp exportBtn filter"
                >
                  <i className="fa fal fa-filter mr-2" />
                  {i18n.t('filters')}
                </button>
              </div>
              <div className="order-3">
                {!limitPermissionRoles.includes(myRoleType) && (
                  <div className="col-auto ml-auto pr-0 btn-sm-us">
                    <PrimaryButton onClickHandler={onClickHandler} i18nLabel="addSMUbtn" isLargeButton />
                  </div>
                )}
              </div>
            </div>
            <Expander expanded={isFilteredTabExpanded}>
              <AdditionalDataListFilters
                dataListID={dataListID}
                transformResponse={transformResponse}
                listURL={USERS_URL}
                role={myRoleType}
              />
            </Expander>
          </div>
        </div>
        <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded px-4">
          <ReactTableV2
            columns={userColumns}
            listID={dataListID}
            listURL={USERS_URL}
            defaultParams={defaultParams}
            transformResponse={transformResponse}
            SubComponent={UserInfoSub}
          />
          <DataListPagination
            Component={Pagination}
            listID={dataListID}
            defaultPageSize={10}
          />
        </div>
      </div>
    </div>
  );
};

UsersTable.propTypes = {
  myRoleType: PropTypes.string.isRequired
};

export default UsersTable;
