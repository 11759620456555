import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { goBack, replace } from 'react-router-redux';
import FormData from 'form-data';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { handleErrorDetailed, handleError } from '../../api_helper';
import { api, deleteOldJwt } from '../../api';
import { signInUpdateUser } from '../SignIn/slice';
import { addNotification } from '../NotificationGenerator/slice';
import {
  settingPrGetUserSucc,
  settingPrHasChanged,
  settingPrGetUser,
  settingPrChange,
  resendToEmail,
  getOEMsByID,
  setOEMs,
  getOEMs,
  setSettingUserStatus
} from './slice';
import { openModalWindow, closeModalWindow, CONFIRMATION_WINDOW_MODAL_ID } from '../ModalWindow/slice';
import i18n, { changeLanguageTo } from '../../i18n';
import { parseStringOfNumbers } from '../validation';

import { OEM_CONNECTED_OEMS_URL, OEM_URL, SIGNUP_RESEND_VERIFY_URL, USER_ME_URL, USER_UPDATE_URL } from '../../api/apiUrls';
import { countryLabels } from '../../components/ReduxFormFields/CountrySelector/constants';
import { getLanguageCode } from '../SignIn/utils/getLanguageCode';

const countriesLength = Object.keys(countryLabels).length;

function settingPrGetUserEpic(action$, state$) {
  return action$.pipe(
    ofType(settingPrGetUser),
    map((action) => action.payload),
    switchMap(({ userID, myself, roleLink, successfulCB }) => {
      let url;
      const params = {};

      if (myself) {
        url = USER_ME_URL;
        params.isWeb = true;
      } else if (roleLink === 'oem') {
        url = `/${roleLink}/${userID}`;
      } else if (roleLink === 'subuser') {
        url = `/users/${roleLink}/${userID}`;
      } else {
        url = `/users/${roleLink}/${userID}`;
      }

      return from(api.get(url, { params })).pipe(
        catchError(handleErrorDetailed),
        map((result) => {
          const state = state$.value;
          if (!result.error) {
            if (typeof successfulCB === 'function' && state.signIn?.user?._id) {
              successfulCB(result, myself);
            }
            return settingPrGetUserSucc({ initialData: result, myself });
          }

          if (myself) {
            deleteOldJwt();
          }

          return addNotification({ type: 'error', text: result.message });
        })
      );
    })
  );
}

function settingPrChangeEpic(action$, state$) {
  return action$.pipe(
    ofType(settingPrChange),
    map((action) => action.payload),
    switchMap(({ myself, roleLink, userID, fields, modalID }) => {
      let url;
      const {
        signIn: {
          user: {
            role: { type: myRoleType }
          }
        }
      } = state$.value;

      if (myself && myRoleType === 'oem') {
        url = OEM_URL;
      } else if (myself && myRoleType !== 'oem') {
        url = USER_UPDATE_URL;
      } else if (roleLink === 'oem') {
        url = `/${roleLink}/${userID}`;
      } else {
        url = `/users/${roleLink}/${userID}`;
      }
      const myForm = new FormData();
      const protectedFields = ['old_email', 'password', 'avatarURL', 'companyLogoUrl', 'old_sm_id', '_submitConfirmed', 'isPropMgmtEndUser'];
      Object.entries(fields).forEach(([fieldID, fieldValue]) => {
        const isProtected = protectedFields.includes(fieldID);
        const isBool = typeof fieldValue === 'boolean';

        if (myRoleType === 'end_user' && (fieldID === 'plant' || fieldID === 'note')) {
          return;
        }

        if (isProtected) {
          return;
        }

        if (fieldID === 'language' && fieldValue === 'Deutsch (Schweiz)') {
          myForm.append(fieldID, 'Deutsch');
          return;
        }

        if (fieldID === 'phone') {
          myForm.append(fieldID, parseStringOfNumbers(fieldValue || ''));
          return;
        }

        if (['avatar', 'company_logo'].includes(fieldID)) {
          // fix for mutation of files
          if (fieldValue?.func) {
            myForm.append(fieldID, fieldValue.func());
            return;
          }

          myForm.append(fieldID, fieldValue);
        }

        if (isBool) {
          myForm.append(fieldID, fieldValue);
          return;
        }

        if (typeof fieldValue === 'string') {
          myForm.append(fieldID, fieldValue.trim());
        }

        if (fieldID === 'billingAddress' && (
          fields.isBillingAddressEnabled
          || roleLink === 'property-management'
          || myRoleType === 'property_management'
        )) {
          myForm.append(fieldID, JSON.stringify(fieldValue));

          return;
        }
        if (fieldID === 'authorizedCountries') {
          if (fieldValue.length === countriesLength) {
            myForm.append(fieldID, '');
          } else {
            myForm.append(fieldID, fieldValue);
          }
        }
      });

      if (fields.save_data === false && !fields._submitConfirmed) {
        return of(
          openModalWindow({
            modalID: CONFIRMATION_WINDOW_MODAL_ID,
            data: {
              sm_id: fields.sm_id,
              save_data: fields.save_data
            }
          })
        );
      }

      return from(api.put(url, myForm, { headers: { 'Content-Type': 'multipart/form-data' } })).pipe(
        catchError(handleErrorDetailed),
        mergeMap((result) => {
          if (myself && result.language) changeLanguageTo(getLanguageCode(result.language), true);

          if (!result.error) {
            if (modalID) {
              if (result.change_email) {
                return of(
                  closeModalWindow({ modalID }),
                  settingPrHasChanged(),
                  replace(),
                  addNotification({ type: 'success', text: i18n.t('changeEmailConfirmSent') })
                );
              }

              return of(
                closeModalWindow({ modalID }),
                settingPrGetUserSucc({
                  initialData: {
                    ...state$.value.settingProfile.initialData,
                    ...result,
                    userName: result.userName
                  },
                  myself
                }),
                settingPrHasChanged(),
                replace(),
                addNotification({ type: 'success', text: i18n.t('changesWereSaved') })
              );
            }
            return of(
              goBack(),
              settingPrHasChanged(),
              addNotification({ type: 'success', text: i18n.t('changesWereSaved') }),
              ...(myself ? [signInUpdateUser({ user: { ...state$.value.signIn.user, ...result, license: state$.value.signIn.user.license } })] : [])
            );
          }
          return of(
            settingPrHasChanged(),
            addNotification({ type: 'error', text: result.message })
          );
        })
      );
    })
  );
}

function reSendEmail(action$) {
  return action$.pipe(
    ofType(resendToEmail),
    map((action) => action.payload),
    switchMap(({ email, language, cb }) => from(api.post(SIGNUP_RESEND_VERIFY_URL, { email }, { language })).pipe(
      catchError(handleError),
      mergeMap((result) => {
        if (result?.email) {
          if (typeof cb === 'function') {
            return of(cb(), addNotification({ type: 'success', text: i18n.t('emailSends') }));
          }

          return of(setSettingUserStatus({ status: 'pending user confirmation' }), addNotification({ type: 'success', text: i18n.t('emailSends') }));
        }

        return of(addNotification({ type: 'error', text: result }));
      })
    ))
  );
}

function getOEMsEpic($action) {
  return $action.pipe(
    ofType(getOEMs),
    map((action) => action.payload),
    switchMap(() => from(api.get(OEM_CONNECTED_OEMS_URL)).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result.error) {
          if (Array.isArray(result.list)) {
            return setOEMs({ oems: result.list });
          }
          return addNotification({ type: 'error', text: result });
        }

        return addNotification({ type: 'error', text: result.message });
      })
    ))
  );
}

function getOEMsByIDEpic($action) {
  return $action.pipe(
    ofType(getOEMsByID),
    map((action) => action.payload),
    switchMap(({ url, params }) => from(api.get(url, { params })).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result.error) {
          if (Array.isArray(result.list)) {
            return setOEMs({ oems: result.list });
          }
          return addNotification({ type: 'error', text: result });
        }

        return addNotification({ type: 'error', text: result.message });
      })
    ))
  );
}

export default combineEpics(
  settingPrGetUserEpic,
  settingPrChangeEpic,
  reSendEmail,
  getOEMsEpic,
  getOEMsByIDEpic
);
